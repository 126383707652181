import { useContext } from 'react';

import NotyfContext from '~app/contexts/notify';

import { strip } from '~app/lib/string';

import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'ramda';

const stripText = strip(300);

export const ERROR = 'error';
export const SUCCESS = 'success';

const sendMessage =
  (notify, operation, options = {}) =>
  (message) => {
    if (isNil(notify) || (operation !== ERROR && operation !== SUCCESS) || isNil(message)) {
      return;
    }

    if (operation === ERROR) {
      const error = new Error(message);
      datadogRum.addError(error);
    }

    notify[operation]({ message: stripText(message), ...options });
  };

/**
 * useNotify
 *
 * Has dependency towards NotyfContext.
 *
 * Takes an operation that can either be "success" or "error" and
 * returns a function that takes a message attribute.
 *
 * Sends a toaster message through the NotyfContext if all attributes are valid.
 *
 * @param {string} operation
 * @returns {function}
 * @param {string} message
 * @returns {void}
 */
const useNotify = (operation = SUCCESS, options) => {
  const notyf = useContext(NotyfContext);

  return sendMessage(notyf, operation, options);
};

export default useNotify;
